import { colors } from 'constants/index';
import { PatientFlowBlock } from 'data/graphql/generated';
import { Group, Text, Rect } from 'react-konva';
import { BlockTree, Cursor } from 'types';
import { BlockIcon } from './BlockIcon';
import { useState } from 'react';

export function BlockStar({
  showStarringModal,
  block,
  changeCursor,
  country,
  starred,
  amountOfStarsExcludingGlobal,
}: {
  showStarringModal: (block: PatientFlowBlock) => void;
  block: BlockTree;
  changeCursor: (cursor: Cursor) => void;
  country: string;
  starred: boolean;
  amountOfStarsExcludingGlobal: number;
}) {
  const [starHover, setStarHover] = useState(false);

  return (
    <Group
      x={0}
      y={0}
      onTap={() => {
        showStarringModal(block);
      }}
      onClick={() => {
        showStarringModal(block);
      }}
      onMouseEnter={() => {
        setStarHover(true);
        changeCursor('pointer');
      }}
      onMouseLeave={() => {
        setStarHover(false);

        changeCursor('default');
      }}
    >
      <Group
       x={10}
       y={10}
      >
       <Rect
        x={-5}
        y={-5}
        height={28}
        width={28}
        sides={200}
        cornerRadius={5}
        fill={starHover ?  country === 'global' && starred ? '#EADEFD' : colors.black10 : 'transparent'}
      />
      <BlockIcon
        type={country === 'global' && starred ? 'ActionPurple' : 'Action'}
        starred={starred}
      />
      {/* star counter */}
      {country === 'global' && (
        <Text
          //star icon width (20) + leftMargin(2)
          x={22}
          y={2}
          text={amountOfStarsExcludingGlobal + ''}
          fill={amountOfStarsExcludingGlobal ? colors.black70 : colors.black30}
          fontFamily="ABCFavorit"
          fontSize={12}
          fontWeight={500}
          align="center"
          lineHeight={1.25}
        />
      )}
      </Group>
      
    </Group>
  );
}
