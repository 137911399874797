import { ButtonLabel, Caption, CountryFlag, Icon } from 'components/shared';
import { colors } from 'constants/index';
import React from 'react';
import { CountryGlobal, CountryGlobalRegional } from 'types';
import styled from 'styled-components/macro';
import _ from 'lodash';

const StyledIcon = styled(Icon)<{ size: number }>`
  svg {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
  }
`;

const Wrapper = styled.div<{ active?: boolean }>`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: ${({ active }) =>
    active ? colors.purple80a : 'transparent'};
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
  &:hover {
    background-color: ${({ active }) =>
      active ? colors.purple80a : colors.purple30a};
  }
`;

const ToggleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  justify-content: center;
`;

interface Props {
  country?: CountryGlobal;
  active?: boolean;
  onClick?(e?: React.MouseEvent<HTMLDivElement>): void;
  viewOnly?: boolean;
  className?: string;
  hideCountryName?: boolean;
  largeIcon?: boolean;
  globalOptionText?: string;
  multiSelect?: boolean;
  selectedRegions?: CountryGlobal[];
}

export const CustomCountriesDropdownToggle: React.FC<Props> = ({
  country,
  active,
  onClick,
  children,
  viewOnly,
  className,
  hideCountryName,
  largeIcon,
  globalOptionText,
  multiSelect,
  selectedRegions,
}) => {
  const multipleRegionsSelected =
    selectedRegions && selectedRegions?.length > 1;
  const firstSelectedRegion = selectedRegions?.[0];
  const globalSelected = selectedRegions?.includes('global');
  const noRegionsSelected = selectedRegions?.length === 0;
  const twoRegionsSelected = selectedRegions?.length === 2;
  const nonGlobalRegion = selectedRegions?.find((r) => r !== 'global');

  return (
    <Wrapper
      className={`${className} cypress-country-dropdown-toggle`}
      active={active}
      onClick={(e) => onClick && onClick(e)}
    >
      <div>
        {!multiSelect ? (
          country === 'global' ? (
            <StyledIcon name="Global" size={30} color={colors.black} />
          ) : (
            <CountryFlag country={country} size="sm" />
          )
        ) : multipleRegionsSelected  ? (
          <StyledIcon name="Global" size={30} color={colors.black} />
        )  : noRegionsSelected ? (
          <StyledIcon name="Global" size={30} color={colors.black} />
        ) : globalSelected ? (
          <StyledIcon name="Global" size={30} color={colors.black} />
        ) : (
          <CountryFlag
            country={nonGlobalRegion as CountryGlobalRegional}
            size="sm"
          />
        )}
      </div>

      <ToggleTextWrapper>
        {!hideCountryName ? (
          multiSelect ? (
            noRegionsSelected ? (
              <ButtonLabel>Choose Region</ButtonLabel>
            ) : globalSelected ? (
              multipleRegionsSelected ? (
                twoRegionsSelected ? (
                  <ButtonLabel>
                    {globalOptionText} + {_.startCase(nonGlobalRegion)}
                  </ButtonLabel>
                ) : (
                  <ButtonLabel>
                    {globalOptionText} 
                  </ButtonLabel>
                )
              ) : (
                <ButtonLabel>{globalOptionText}</ButtonLabel>
              )
            ) : multipleRegionsSelected ? (
              <ButtonLabel>{selectedRegions?.length} countries</ButtonLabel>
            ) : (
              <ButtonLabel>{_.startCase(firstSelectedRegion)}</ButtonLabel>
            )
          ) : (
            <ButtonLabel color={colors.white}>
              {country === 'global' && globalOptionText
                ? _.capitalize(globalOptionText)
                : _.startCase(country)}
            </ButtonLabel>
          )
        ) : null}

        {viewOnly ? (
          <Caption color={colors.greyMedium}>View only</Caption>
        ) : null}
      </ToggleTextWrapper>

      <Icon
        name={active ? 'Chevron-up' : 'Chevron-down'}
        size={largeIcon ? 30 : 20}
        color={colors.white}
        className="chevron"
      />
      {children}
    </Wrapper>
  );
};
