import { colors } from 'constants/index';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Collaboration } from '../index';
import { ButtonRound } from '../ButtonRound';

import { Icon } from '../Icon';
import Textarea from 'react-expanding-textarea';
import { BodyNormal, BodySmall, ButtonLabel, Caption } from '../TextStyles';

import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import {
  Exact,
  Maybe,
  Observation as ObservationType,
  ObservationCreateMutation,
  ObservationDeleteMutation,
  ObservationFragment,
  ObservationInput,
  ObservationsDocument,
  ObservationsQueryVariables,
  ObservationUpdateInput,
  ObservationUpdateMutation,
  Stakeholder,
  Step,
} from 'data/graphql/generated';
import { MutationFunctionOptions } from '@apollo/client';
import {
  apolloDeleteHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';
import { mapDBStepToReadableStep } from 'utils/mapDBStepToReadableStep';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { useAuthContext } from 'contexts/AuthContext';
import { formatCreatedDate } from 'utils/formatCreatedDate';
import useDesktop from 'hooks/useDesktop';
import { CheckCircle } from '../CheckCircle';
import { verifyUserRole } from 'utils/verifyUserRole';

export const GradientBG = styled.div<{
  selected?: boolean;
}>`
  background: ${({ selected }) =>
    selected ? colors.purplePinkGradient : colors.black30a};
  padding: 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 115px;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const DropdownWrapper = styled.div<{ show: boolean }>`
  display: flex;
  position: absolute;
  top: 9px;
  left: 9px;
  z-index: 2;
  overflow: hidden;
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};

  ${({ show }) => {
    return show
      ? css`
          max-height: 185px;
          max-width: 214px;
        `
      : css`
          max-height: 0px;
          max-width: 0px;
        `;
  }}

  transition: all 0.1s ease;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextareaWrapper = styled(Textarea) <{
  $text: boolean;
  $textValue: boolean;
}>`
  width: 100%;
  margin-top: 9px;
  margin-bottom: 5px;

  border: none;
  padding: 0;
  overflow-y: hidden;
  resize: none;

  font-family: ABCFavorit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};
  outline: 0.5px solid
    ${({ $textValue }) => ($textValue ? 'transparent' : colors.red)};
  &:focus {
    outline: 0.5px solid
      ${({ $text, $textValue }) =>
    $textValue ? colors.blue : $text ? colors.red : colors.blue};
  }
`;

const DropdownToggle = styled.div<{ show?: boolean; readonly?: boolean }>`
  z-index: 1;
  display: flex;
  cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};
  pointer-events: ${({ show, readonly }) =>
    readonly || show ? 'none' : 'all'};
  svg {
    opacity: ${({ show }) => Number(!show)};
    transition: all 0.3s;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  p:first-child {
    text-transform: capitalize;
  }
`;
const DeleteConfirmation = styled.div<{ show: boolean }>`
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.white};
  z-index: 2;
  border-radius: inherit;
  p {
    text-align: center;
  }
`;

const DeleteConfirmationBody = styled.div`
  margin-top: 5px;
`;

const DeleteConfirmationFooter = styled.div`
  display: flex;
  > * + * {
    margin-left: 20px;
  }
  margin-top: 10px;
  p {
    cursor: pointer;
  }
`;
const ReadonlyText = styled(BodySmall)`
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const RemoveLabelWrapper = styled(ButtonLabel)`
  margin-left: auto;
`;

const StyledDeleteButton = styled(ButtonRound) <{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  opacity: ${({ show }) => Number(show)};
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
`;

interface ReadonlyProps {
  readonly: boolean;
  data: Maybe<
    {
      __typename?: 'Observation' | undefined;
    } & {
      __typename?: 'Observation' | undefined;
    } & Pick<
      ObservationType,
      | 'step'
      | 'text'
      | 'id'
      | 'companyId'
      | 'strategyId'
      | 'drugId'
      | 'lastUpdated'
      | 'createdAt'
      | 'stakeholder'
    >
  >;
  step?: never;
  drugId?: never;
  strategyId?: never;
  createObservation?: never;
  updateObservation?: never;
  deleteObservation?(): void;
  setUpdating?: never;
  preview?: never;
  stakeholder?: never;
  removePreview?(): void;
  openObservationDropdown?: never;
  setOpenObservationDropdown?: never;
  disableCollaboration?: boolean;
  checked?: boolean;
  onClick?(): void;
}

interface Props {
  step?: Step;
  drugId?: string;
  strategyId?: string;
  data: Maybe<
    {
      __typename?: 'Observation' | undefined;
    } & {
      __typename?: 'Observation' | undefined;
    } & Pick<
      ObservationType,
      | 'step'
      | 'text'
      | 'id'
      | 'companyId'
      | 'strategyId'
      | 'drugId'
      | 'lastUpdated'
      | 'createdAt'
      | 'stakeholder'
    >
  >;
  createObservation: (
    options?:
      | MutationFunctionOptions<
        ObservationCreateMutation,
        Exact<{
          data: ObservationInput;
        }>
      >
      | undefined
  ) => Promise<any>;
  updateObservation: (
    options?:
      | MutationFunctionOptions<
        ObservationUpdateMutation,
        Exact<{
          id: number;
          data: ObservationUpdateInput;
        }>
      >
      | undefined
  ) => Promise<any>;
  deleteObservation?: (
    options?:
      | MutationFunctionOptions<
        ObservationDeleteMutation,
        Exact<{
          id: number;
        }>
      >
      | undefined
  ) => Promise<any>;
  setUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  preview?: boolean;
  stakeholder?: Stakeholder;
  removePreview?(): void;
  readonly?: never;
  openObservationDropdown: number | boolean;
  setOpenObservationDropdown: React.Dispatch<
    React.SetStateAction<number | false>
  >;
  disableCollaboration?: boolean;
  checked?: boolean;
  onClick?(): void;
}

export const Observation = ({
  step,
  createObservation,
  updateObservation,
  deleteObservation,
  data,
  drugId,
  strategyId,
  setUpdating,
  preview,
  stakeholder: pageStakeholder,
  removePreview,
  readonly,
  openObservationDropdown,
  setOpenObservationDropdown,
  disableCollaboration = false,
  checked,
  onClick,
}: Props | ReadonlyProps) => {
  const [stakeholder, setStakeholder] = useState<Stakeholder>(
    data?.stakeholder || pageStakeholder || Stakeholder.Healthcare
  );
  useEffect(() => {
    if (data?.stakeholder) {
      setStakeholder(data?.stakeholder);
    }
  }, [data?.stakeholder]);
  const [textValue, setTextValue] = useState(data?.text || '');
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const queryVars: ObservationsQueryVariables = {
    where: { strategyId: Number(strategyId) },
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const isDesktop = useDesktop();

  const text = data?.text || '';

  useClickOutsideComponent(
    containerRef,
    (event) => {
      if (!readonly) {
        if (preview && removePreview && !textValue) {
          removePreview();
        }
        confirmDelete && setConfirmDelete(false);
      }
    },
    ['add-observation']
  );

  useClickOutsideComponent(textAreaRef, (event) => {
    setShowDeleteButton(false);
  });

  if (readonly && data) {
    return (
      <GradientBG selected={checked} onClick={onClick}>
        <Wrapper className="cypress-observation">
          <Head>
            {deleteObservation && isDesktop ? (
              <RemoveLabelWrapper
                className="cypress-observation-delete"
                onClick={() => {
                  deleteObservation?.();
                }}
                color={colors.greyDark}
              >
                Remove
              </RemoveLabelWrapper>
            ) : null}

            <DropdownItem
              stakeholder={data?.stakeholder}
            />

            <div style={{ marginLeft: 'auto' }}>
              {!!onClick && <CheckCircle complete={checked || false} />}
            </div>
          </Head>

          <ReadonlyText>{data?.text}</ReadonlyText>

          <Footer>
            <BodySmall color={colors.greyDark}>
              {mapDBStepToReadableStep(data?.step)}
            </BodySmall>
          </Footer>
        </Wrapper>
      </GradientBG>
    );
  }

  if (
    step &&
    deleteObservation &&
    drugId &&
    strategyId &&
    setUpdating &&
    stakeholder &&
    removePreview &&
    setOpenObservationDropdown
  )
    return (
      <GradientBG selected={checked} onClick={onClick}>
        <Wrapper className="cypress-observation" ref={containerRef}>
          <>
            <DeleteConfirmation show={confirmDelete}>
              <div>
                <BodyNormal color={colors.black}>
                  Delete this observation?
                </BodyNormal>
              </div>
              <DeleteConfirmationBody>
                <BodyNormal color={colors.black60}>
                  Any discussion and files will be lost. It will be removed from
                  any insights it is supporting.
                </BodyNormal>
              </DeleteConfirmationBody>
              <DeleteConfirmationFooter>
                <ButtonLabel
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  Cancel
                </ButtonLabel>
                <ButtonLabel
                  className="cypress-observation-delete-confirm"
                  color={colors.red}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (Number.isInteger(data?.id) && data?.id) {
                      await deleteObservation({
                        variables: { id: data.id },
                        update: apolloDeleteHelper({
                          responseField: 'observationDelete',
                          query: ObservationsDocument,
                          queryVars,
                          queryName: 'observations',
                        }),
                      });
                    }

                    if (preview) {
                      removePreview();
                    }
                    setConfirmDelete(false);
                    setShowDeleteButton(true);
                  }}
                >
                  Delete
                </ButtonLabel>
              </DeleteConfirmationFooter>
            </DeleteConfirmation>
            <StyledDeleteButton
              className="cypress-observation-delete"
              innerRef={deleteButtonRef}
              iconName="Trash"
              level="secondary"
              size="small"
              tooltip=""
              onClick={() => {
                setConfirmDelete(true);
                setShowDeleteButton(false);
              }}
              show={showDeleteButton}
            />
          </>

          <Head>
            <DropdownWrapper
              show={openObservationDropdown === (data?.id || -1)}
            >
              <Dropdown
                id={data?.id + '' || 'preview'}
                setShow={(e) => {
                  setOpenObservationDropdown(false);
                }}
                show={openObservationDropdown === (data?.id || -1)}
                updateStakeholder={async (stakeholder) => {
                  if (!isLead) return;

                  if (!!textValue && data?.id) {
                    setStakeholder(stakeholder);

                    await updateObservation({
                      variables: {
                        id: data.id,
                        data: {
                          text: textValue,
                          stakeholder,
                        },
                      },
                      update: apolloUpdateHelper({
                        responseField: 'observationUpdate',
                        query: ObservationsDocument,
                        queryVars,
                        queryName: 'observations',
                      }),
                    });
                  } else if (preview) {
                    setStakeholder(stakeholder);
                  }
                }}
                stakeholder={stakeholder}
              />
            </DropdownWrapper>

            <DropdownToggle
              show={openObservationDropdown === (data?.id || -1)}
              onClick={(e) => {
                if (!isLead) return;
                setOpenObservationDropdown(data?.id || -1);
              }}
            >
              <DropdownItem stakeholder={stakeholder} />
              {isLead ? (
                <Icon name="Chevron-down" size={30} color={colors.greyDark} />
              ) : null}
            </DropdownToggle>

            <div style={{ marginLeft: 'auto' }}>
              <Caption color={colors.greyDark}>
                {data && formatCreatedDate(Number(data.createdAt))}
              </Caption>
            </div>
          </Head>
          {isLead ? (
            <TextareaWrapper
              ref={textAreaRef}
              $text={!!text}
              $textValue={!!textValue}
              autoFocus={preview}
              rows={1}
              value={textValue}
              onFocus={() => {
                if (!isLead) return;
                setShowDeleteButton(true);
              }}
              onBlur={async (e) => {
                if (!isLead) return;

                const deleteBtn = deleteButtonRef.current;

                const newFocusedElement = e.relatedTarget as Node | null;

                if (newFocusedElement) {
                  if (!deleteBtn?.contains(newFocusedElement)) {
                    setShowDeleteButton(false);
                  }

                  //If clicking on "Assign observations, don't create the observations yet"
                  if (containerRef.current?.contains(newFocusedElement)) {
                    return;
                  }
                }

                if (!text && !!textValue) {
                  preview && setUpdating(true);

                  await createObservation({
                    variables: {
                      data: {
                        strategy: Number(strategyId),
                        drug: Number(drugId),
                        text: textValue,
                        step,
                        stakeholder,
                      },
                    },

                    update: (cache, { data }) => {
                      removePreview();

                      const queryData = cache.readQuery({
                        query: ObservationsDocument,
                        variables: queryVars,
                      }) as {
                        observations: {
                          items: ObservationFragment[];
                          total: number;
                        };
                      };

                      const newItems = [
                        ...queryData['observations'].items,
                        data?.observationCreate,
                      ];

                      cache.writeQuery({
                        query: ObservationsDocument,
                        variables: queryVars,
                        data: {
                          observations: {
                            ...queryData,
                            items: newItems,
                            total: newItems.length,
                          },
                        },
                      });
                    },
                  });

                  preview && setUpdating(false);
                } else if (!!textValue && textValue !== text && data) {
                  preview && setUpdating(true);

                  await updateObservation({
                    variables: {
                      id: data.id,
                      data: {
                        text: textValue,
                        stakeholder,
                      },
                    },
                    update: apolloUpdateHelper({
                      responseField: 'observationUpdate',
                      query: ObservationsDocument,
                      queryVars,
                      queryName: 'observations',
                    }),
                  });
                  preview && setUpdating(false);
                } else {
                  setTextValue(text);
                }
              }}
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                setTextValue(target.value);
              }}
            />
          ) : (
            <ReadonlyText>{data?.text}</ReadonlyText>
          )}
          <Footer>
            <BodySmall color={colors.greyDark}>
              {mapDBStepToReadableStep(data?.step || step)}
            </BodySmall>
            {/* @ts-ignore */}
            <Collaboration collaboration={data?.collaboration} />
          </Footer>
        </Wrapper>
      </GradientBG>
    );

  return null;
};
