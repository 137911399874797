import { Observations } from 'components/Observations';
import { PatientFlowView } from 'components/PatientFlow';
import { FiguresDistributionModal } from 'components/PatientFlow/FiguresDistributionModal';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Maybe,
  PatientFlowBlock,
  PatientFlowDocument,
  PatientFlowQuery,
  PatientFlowUpdateInput,
  PatientFlowValueFragment,
  PatientFlowValueFragmentDoc,
  PatientFlowValueUpsertInput,
  PatientJourneyColumnUpdateInput,
  Role,
  Stakeholder,
  Step,
  usePatientFlowBlockCreateMutation,
  usePatientFlowBlockDeleteMutation,
  usePatientFlowBlockUpdateMutation,
  usePatientFlowQuery,
  usePatientFlowUpdateMutation,
  usePatientFlowValueUpsertMutation,
  usePatientJourneyColumnUpdateMutation,
  useStrategyQuery,
  usePatientFlowBlockStarUpdateMutation,
  usePatientFlowBlockStarUpsertMutation,
} from 'data/graphql/generated';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlockTree, Country, CountryGlobal } from 'types';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';
import { ErrorModal } from 'components/ErrorModal';
import { globalContributor, polling } from 'constants/index';
import { ApolloCache, InternalRefetchQueriesInclude } from '@apollo/client';
import orderBy from 'lodash/orderBy';

interface Params {
  drugId: string;
  strategyId: string;
}

export const PatientFlow: React.FC = () => {
  const [disableActions, setDisableActions] = useState(false);
  const [stakeholderDefinitions, setStakeholderDefinitions] = useState<any>([]);
  const { drugId, strategyId }: Params = useParams();
  const [{ user }] = useAuthContext();
  const userCountry = user?.country as Country;
  const [blockInUseModal, setBlockInUseModal] = useState<boolean>(false);
  const [
    leveragePointInUseModal,
    setLeveragePointInUseModal,
  ] = useState<boolean>(false);

  const [distributionModalOpen, setDistributionModalOpen] = useState<
    { title: string; type: 'block' | 'stage'; id: number } | false
  >(false);

  const [distributionModalData, setDistributionModalData] = useState<
    | {
        region: string;
        value?: number | null;
      }[]
    | undefined
  >([]);

  const {
    data: strategyData,
    error: strategyError,
    loading: strategyLoading,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const allCountries = strategyData?.strategy?.users
    .filter(
      (user) =>
        user.role !== Role.Lead &&
        user?.country !== null &&
        user?.country !== globalContributor
    )
    ?.map((user) => user?.country);

  const uniqueCountries = [
    ...Array.from(new Set(allCountries)),
  ].sort() as Country[];

  const {
    data,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
  } = usePatientFlowQuery({
    variables: {
      strategyId: Number(strategyId),
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const [updatePatientFlow] = usePatientFlowUpdateMutation();

  const [completedRegions, setCompletedRegions] = useState<string[]>([]);

  const [country, setCountry] = useState<CountryGlobal>('global');

  const [
    starringModalBlock,
    setStarringModalBlock,
  ] = useState<PatientFlowBlock | null>(null);

  const dataLoaded = useRef(false);

  useEffect(() => {
    //Update patient flow data whenever the country is changed
    const refetchPatientFlowData = async () => {
      await refetch();
    };

    refetchPatientFlowData();
  }, [country, refetch]);

  useEffect(() => {
    if (data && !loading && !dataLoaded.current) {
      dataLoaded.current = true;

      setCompletedRegions(data?.patientFlow?.regionCompletions as string[]);
      setCountry(
        data?.patientFlow?.layoutCompleted
          ? user?.role === 'LEAD'
            ? 'global'
            : user?.country === globalContributor
            ? 'global'
            : userCountry
          : 'global'
      );
    }
  }, [data, loading, user?.country, user?.role, userCountry]);

  useEffect(() => {
    setCompletedRegions(
      (data?.patientFlow?.regionCompletions as string[]) || ['']
    );
  }, [data?.patientFlow?.regionCompletions]);

  const refetchPatientFlowField: {
    refetchQueries: InternalRefetchQueriesInclude;
  } = {
    refetchQueries: [
      {
        query: PatientFlowDocument,
        variables: {
          strategyId: Number(strategyId),
        },
      },
    ],
  };

  async function setPatientFlowRegionComplete(regions: CountryGlobal[]) {
    if (!data?.patientFlow?.id) return;
    const regionCompletions = (regions as unknown) as Maybe<Maybe<string>[]>;
    try {
      await updatePatientFlow({
        variables: {
          id: data?.patientFlow.id,
          data: {
            regionCompletions,
          },
        },
        ...refetchPatientFlowField,
      });
      setCompletedRegions(regions);
    } catch (err) {
      console.error(err);
    }
  }

  const patientFlowUpdate = async (patientFlowData: PatientFlowUpdateInput) => {
    if (!data?.patientFlow?.id) return;

    try {
      await updatePatientFlow({
        variables: {
          id: data?.patientFlow.id,
          data: { ...patientFlowData },
        },
        ...refetchPatientFlowField,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const [upsertPatientFlowValue] = usePatientFlowValueUpsertMutation();

  const [createPatientFlowBlock] = usePatientFlowBlockCreateMutation();
  const [updatePatientFlowBlock] = usePatientFlowBlockUpdateMutation();

  async function patientFlowBlockCreate(
    columnId: number,
    parentId: number
  ): Promise<PatientFlowBlock | undefined> {
    setDisableActions(true);
    const res = await createPatientFlowBlock({
      variables: {
        data: {
          text: 'New block',
          columnId,
          parentId,
          drugId: Number(drugId),
          strategyId: Number(strategyId),
        },
      },
      ...refetchPatientFlowField,
    });
    setDisableActions(false);
    if (res?.data?.patientFlowBlockCreate) {
      // @ts-ignore
      return res?.data?.patientFlowBlockCreate;
    }
    return undefined;
  }

  async function patientFlowBlockUpdate(
    id: number,
    values: Partial<PatientFlowBlock>
  ) {
    const block = data?.patientFlowBlocks?.items.find((b) => b.id === id);
    if (!block) return;
    try {
      await updatePatientFlowBlock({
        variables: {
          id,
          data: {
            ...values,
          },
        },
        ...refetchPatientFlowField,
        optimisticResponse: {
          patientFlowBlockUpdate: {
            ...block,
            ...values,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }
  const [columnUpdate] = usePatientJourneyColumnUpdateMutation();

  async function patientFlowColumnUpdate(
    value: PatientJourneyColumnUpdateInput & { id: number }
  ) {
    const data = {
      name: value.name,
      idx: value.idx,
      width: value.width,
    };
    await columnUpdate({
      variables: {
        data,
        id: Number(value.id),
      },
    });
  }

  const [deletePatientFlowBlock] = usePatientFlowBlockDeleteMutation();

  async function patientFlowBlockDelete(id: number) {
    const flowBlock = data?.patientFlowBlocks?.items.find((block) => {
      return block.id === id;
    });

    setDisableActions(true);
    try {
      deletePatientFlowBlock({
        variables: { id },
        // The server deletes this block's children but by updating the cache, we should not be rendering the children
        optimisticResponse: !!flowBlock
          ? {
              patientFlowBlockDelete: {
                ...flowBlock,
              },
            }
          : undefined,
        update: (cache, { data }) => {
          if (!data?.patientFlowBlockDelete?.__typename) return;
          const cachedPatientFlow = cache.readQuery({
            query: PatientFlowDocument,
            variables: { strategyId: Number(strategyId) },
          }) as PatientFlowQuery;

          const filteredBlocks = cachedPatientFlow?.patientFlowBlocks?.items.filter(
            (block) => block.id !== data.patientFlowBlockDelete.id
          );

          cache.writeQuery({
            query: PatientFlowDocument,
            variables: { strategyId: Number(strategyId) },
            data: {
              ...cachedPatientFlow,
              patientFlowBlocks: {
                ...cachedPatientFlow.patientFlowBlocks,
                items: filteredBlocks,
                total: filteredBlocks?.length,
              },
            },
          });
        },
      });
    } catch (err) {
      if (
        err instanceof Error &&
        err.message.includes(`Later content depends on item`)
      ) {
        setBlockInUseModal(true);
      } else {
        console.error(err);
      }
    }
    setDisableActions(false);
  }

  const pFlowValuesData = data?.patientFlowValues;

  async function updateChildrenValues(
    flowValueId: number,
    newValueTracker: Record<number, number | null | undefined>,
    layoutBlocks: BlockTree[] | undefined,
    cache: ApolloCache<any>
  ) {
    // get Children
    const children = layoutBlocks?.filter(
      (block) => block.parentId === flowValueId
    );

    // return if no children - exit condition
    if (!children?.length) {
      return;
    }

    const initialValueOfParent = pFlowValuesData?.items?.find(
      (value) =>
        value?.patientFlowBlockId === flowValueId && value.region === country
    )?.value;

    for (const child of children) {
      const childPatientFlowObjectValue = pFlowValuesData?.items?.find(
        (value) =>
          value?.patientFlowBlockId === child.id && value.region === country
      );

      const childValue = childPatientFlowObjectValue?.value;

      const newParentValue = newValueTracker[flowValueId];
      if (
        typeof childValue === 'number' &&
        typeof initialValueOfParent === 'number' &&
        typeof newParentValue === 'number'
      ) {
        // Get its % of the parents original value
        const percentageAgainstOldParentValue =
          childValue / initialValueOfParent;

        const newChildValue = percentageAgainstOldParentValue * newParentValue;

        newValueTracker[child.id] = newChildValue;
        if (child.children.length > 0) {
          updateChildrenValues(child.id, newValueTracker, layoutBlocks, cache);
        }

        cache.writeFragment({
          id: `PatientFlowValue:${child.id}`,
          fragment: PatientFlowValueFragmentDoc,
          fragmentName: 'patientFlowValue',
          data: {
            ...child,
          },
        });
      }
    }
  }

  function patientFlowValueUpsert(layoutBlocks: BlockTree[] | undefined) {
    return async (
      id: number | undefined,
      data: Omit<PatientFlowValueUpsertInput, 'drugId' | 'strategyId'>,
      isPercentage: boolean
    ) => {
      const currentBlockValue = pFlowValuesData?.items?.find((blockValue) => {
        return blockValue?.id === id;
      });

      try {
        await upsertPatientFlowValue({
          variables: {
            id: id || null,
            data: {
              ...data,
              region: country,
              drugId: Number(drugId),
              strategyId: Number(strategyId),
              isPercentageUpdate: isPercentage,
            },
          },
          optimisticResponse:
            !!id && !!currentBlockValue
              ? {
                  patientFlowValueUpsert: {
                    __typename: 'PatientFlowValue',
                    ...currentBlockValue,
                    ...data,
                    id,
                  },
                }
              : undefined,

          update: (cache, { data }) => {
            const cachedData = data;
            if (!cachedData?.patientFlowValueUpsert?.__typename) return;
            const cachedPatientFlowValue = cache.readFragment({
              id: `PatientFlowValue:${id}`,
              fragment: PatientFlowValueFragmentDoc,
              fragmentName: 'patientFlowValue',
            }) as PatientFlowValueFragment;

            // if updating value

            cache.writeFragment({
              id: `PatientFlowValue:${id}`,
              fragment: PatientFlowValueFragmentDoc,
              fragmentName: 'patientFlowValue',
              data: {
                ...cachedPatientFlowValue,
              },
            });

            // update all children in cache here
            if (
              typeof id !== 'undefined' &&
              isPercentage &&
              !!layoutBlocks &&
              cachedData.patientFlowValueUpsert.value !== undefined
            ) {
              const newValueTracker = {} as Record<
                number,
                number | undefined | null
              >;
              newValueTracker[cachedData.patientFlowValueUpsert.id] =
                cachedData.patientFlowValueUpsert.value;
              updateChildrenValues(
                cachedData.patientFlowValueUpsert.id,
                newValueTracker,
                layoutBlocks,
                cache
              );
            }
          },
          ...refetchPatientFlowField,
        });
      } catch (err) {
        console.error(err);
      }
    };
  }

  useEffect(() => {
    if (distributionModalOpen) {
      if (distributionModalOpen.type === 'block') {
        const blockValues = data?.patientFlowValues?.items.filter(
          (value) => value?.patientFlowBlockId === distributionModalOpen.id
        ) as { region: string; value: number }[];

        setDistributionModalData(
          blockValues ? orderBy(blockValues, ['value'], ['desc']) : undefined
        );
      }
      if (distributionModalOpen.type === 'stage') {
        const stageData = data?.patientFlowValues?.items.filter((value) => {
          return value?.columnId === distributionModalOpen.id;
        });

        // Get sums of regions
        const aggregateRegion = stageData?.reduce((acc, current) => {
          const valueBlock = data?.patientFlowBlocks?.items.find(
            (block) => block?.id === current?.patientFlowBlockId
          );

          const parentBlock = data?.patientFlowBlocks?.items.find(
            (pBlock) => pBlock?.id === valueBlock?.parentId
          );

          //only include these if the parent is in a different stage
          if (parentBlock?.columnId !== valueBlock?.columnId) {
            if (current?.region) {
              const region = current?.region;
              const value = current?.value || 0;

              const aggregatedRegion = acc.find(
                (element) => element?.region === region
              );

              //if the region does not exist in the array
              if (!aggregatedRegion) {
                acc.push({ region: region, value });
                return acc;
              }

              aggregatedRegion.value += value;
              return acc;
            }
          }

          return acc;
        }, [] as { region: string; value: number }[]);

        setDistributionModalData(orderBy(aggregateRegion, ['value'], ['desc']));
      }
    }
  }, [distributionModalOpen, data]);

  const [patientFlowValues, setPatientFlowValues] = useState(
    data?.patientFlowValues
  );

  useEffect(() => {
    //Filter the patient flow values to the correct country
    const countryPatientFlowValues = data?.patientFlowValues?.items.filter(
      (value) => value?.region === country
    );
    const patientFlowValues = {
      items: countryPatientFlowValues || [],
      total: countryPatientFlowValues?.length || 0,
    } as PatientFlowQuery['patientFlowValues'];
    setPatientFlowValues(patientFlowValues);
  }, [data?.patientFlowValues, country]);

  const countriesStarredOnBlocks =
    data?.patientFlowBlocks?.items.reduce(
      (acc, block) =>
        block?.id
          ? {
              ...acc,
              [block?.id]: !!block?.stars
                ? block?.stars
                    ?.map((star) => star.region || '')
                    .filter((val) => !!val)
                : [],
            }
          : acc,
      {} as Record<number, string[]>
    ) || {};

  const idsOfBlocksInStages =
    data?.patientFlowBlocks?.items.reduce((acc, block) => {
      if (typeof block?.columnId === 'undefined') {
        return acc;
      }

      if (!acc[block?.columnId]) {
        acc[block?.columnId] = [];
      }

      acc[block?.columnId].push(block?.id);

      return acc;
    }, {} as Record<number, number[]>) || {};

  const valueWasEditedByMR = !!data?.patientFlowValues?.items.some(
    (value) => value?.region !== 'global' && value?.value !== null
  );

  useEffect(() => {
    if (strategyData?.strategy?.StakeholderDefinition
      && strategyData?.strategy?.StakeholderDefinition.length > 0
    ) {
      const definitions = strategyData?.strategy?.StakeholderDefinition?.filter(
        (definition) =>
          !!definition.title &&
          definition.stakeholder === Stakeholder.Patient
      );
      setStakeholderDefinitions(definitions)
    }
  }, [strategyData, setStakeholderDefinitions])


  const starsOfBlock = 
    data?.patientFlowBlocks?.items.find(
      (block) => block?.id && block?.id === starringModalBlock?.id
    )?.stars || []
  
  const starForCountry = starsOfBlock.find((star) =>
    country !== 'global' ? star.region === country : star.global
  );

  const [
    starredStakeholderDefinitionIds,
    setStarredStakeholderDefinitionIds,
  ] = React.useState<number[]>(
    starForCountry?.stakeholderDefinitions.map((s) => s.id) || []
  );

  const [upsertStar] = usePatientFlowBlockStarUpsertMutation();
  const [updateStar] = usePatientFlowBlockStarUpdateMutation();
  const starringBlock = async () => {
    const patientDefinition: any = stakeholderDefinitions[0]
    const stars = starForCountry?.stakeholderDefinitions.map((s) => s.id) || [];
    const isStarred = stars?.includes(
      patientDefinition.id
    );

    const blockId = starringModalBlock?.id;

    if (isStarred) {
      if (starForCountry) {
        try {
          const newStakeholderDefinitions = starredStakeholderDefinitionIds.filter(
            (s) => s !== patientDefinition.id
          );

          setStarredStakeholderDefinitionIds(newStakeholderDefinitions);

          await updateStar({
            variables: {
              id: starForCountry?.id,
              data: {
                strategy: Number(strategyId),
                removeStakeholderDefinition: patientDefinition.id,
              },
            },
          });
        } catch (err) {
          if (
            err instanceof Error &&
            err.message === `Later content depends on item`
          ) {
            setStarredStakeholderDefinitionIds(starredStakeholderDefinitionIds);
            setLeveragePointInUseModal(true);
          } else {
            alert(err);
          }
        }
      }
    } else {
      try {
        const newStakeholderDefinitions = [
          ...starredStakeholderDefinitionIds,
          patientDefinition.id,
        ];

        setStarredStakeholderDefinitionIds(newStakeholderDefinitions);

        const data = {
          patientFlowBlock: Number(blockId),
          stakeholderDefinition: Number(patientDefinition.id),
          strategy: Number(strategyId),
          global: country === 'global',
          region: country === 'global' ? undefined : country,
        };

        await upsertStar({
          variables: {
            data,
          },
          ...refetchPatientFlowField,
        });
      } catch (err) {
        setStarredStakeholderDefinitionIds(starredStakeholderDefinitionIds);
        alert(err);
      }
    }
    setStarringModalBlock(null)
  }


  useEffect(() => {
    if (starringModalBlock){
      if (stakeholderDefinitions?.length > 0) {
         starringBlock();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starringModalBlock, stakeholderDefinitions]);

  return (
    <>
      <ErrorWrapper
        isLoading={loading || strategyLoading}
        errors={[strategyError || error]}
        dataMissing={!data?.patientFlow}
      >
        <Observations step={Step.Patientflow} />
        
        <FiguresDistributionModal
          title={distributionModalOpen ? distributionModalOpen['title'] : ''}
          isOpen={!!distributionModalOpen}
          setIsOpen={setDistributionModalOpen}
          data={distributionModalData}
          allCountries={uniqueCountries}
          countriesStarredOnBlocks={countriesStarredOnBlocks}
          caller={distributionModalOpen}
          idsOfBlocksInStages={idsOfBlocksInStages}
        />

        <ErrorModal
          title="Cannot delete this block"
          text="Content in later steps depends on this block or its children as a leverage point. Remove content and try again."
          visible={blockInUseModal}
          handleClose={() => setBlockInUseModal(false)}
        />

        <ErrorModal
          visible={leveragePointInUseModal}
          handleClose={() => setLeveragePointInUseModal(false)}
          title="Cannot remove this leverage point"
          text="Content in later steps depends on this leverage point. Remove content and try again."
        />

        <PatientFlowView
          user={user}
          country={country}
          allCountriesExcludingGlobal={uniqueCountries}
          setCountry={setCountry}
          loading={loading}
          completedRegions={completedRegions}
          setPatientFlowRegionComplete={setPatientFlowRegionComplete}
          patientFlow={data?.patientFlow}
          stages={data?.patientJourneyColumns}
          blocks={data?.patientFlowBlocks}
          values={patientFlowValues}
          drugId={Number(drugId)}
          strategyId={Number(strategyId)}
          patientFlowUpdate={patientFlowUpdate}
          patientFlowBlockCreate={patientFlowBlockCreate}
          patientFlowBlockUpdate={patientFlowBlockUpdate}
          patientFlowBlockDelete={patientFlowBlockDelete}
          patientFlowColumnUpdate={patientFlowColumnUpdate}
          upsertPatientFlowValue={patientFlowValueUpsert}
          setDistributionModalOpen={setDistributionModalOpen}
          valueWasEditedByMR={valueWasEditedByMR}
          disableActions={disableActions}
          showStarringModal={(block: PatientFlowBlock) => {
            setStarringModalBlock(block);
          }}
        />
      </ErrorWrapper>
    </>
  );
};
