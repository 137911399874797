import {
  BodySmall,
  CheckCircle,
  Collaboration,
  Subtitle2,
  Tooltip,
  ButtonLabel,
  Icon,
  TabGroup,
  ButtonRound,
} from 'components/shared';
import { colors } from 'constants/index';
import {
  createRef,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import styled from 'styled-components/macro';
import { TreatmentIcon } from './TreatmentIcon';

// https://github.com/tsayen/dom-to-image
// This allows us to convert the html to an Image, and allows you to style the exported Component
// Works on Safari
// import domtoimage from 'dom-to-image';
import {
  CompetitiveLandscapeOpportunityFragment,
  CompetitiveLandscapeOpportunityUpdateMutationFn,
  useCompetitiveLandscapeOpportunityUpdateMutation,
  CompetitorFragment,
  PostItGroupFragment,
  Stakeholder,
  CompetitiveLandscapeRatingFragment,
  CompetitiveLandscapeOpportunity,
  useStrategyWithDrugQuery,
  } from 'data/graphql/generated';
import { average } from 'utils/average';
import { useAuthContext } from 'contexts/AuthContext';
import ReactTooltip from 'react-tooltip';
import { useWidth } from 'hooks/useWidth';
import { EvaluationGraphsCompetitor } from './EvaluationGraphsCompetitor';
import { EvaluationGraphsStakeholder } from './EvaluationGraphsStakeholder';
import { GraphScroller } from './GraphScroller';
import {
  DistributionModal,
  DistributionModalProps,
  DistributionModalValues,
} from './DistributionModal';
import lodash, { throttle } from 'lodash';
import { ErrorModal } from 'components/ErrorModal';
import { Loading } from 'components/Loading';
import { device } from 'utils/breakpoints';
import { Image } from './CompetitorTab';
import { verifyUserRole } from 'utils/verifyUserRole';
import { IconName } from 'types';


const AverageAcross = styled.div`
background: white;
    margin-bottom: 2px;
    padding: 10px;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 18px;
font-style: normal;
font-weight: 400;
`;
const ViewBy = styled.div`
color: var(--Grey---Dark, #696975);
/* Body Small */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
`;


const TitleWrapper = styled.div<{ showDistribution: boolean, approved:boolean }>`
  min-width: 260px;
  max-width: 260px;
  border-radius: ${({ approved }) => approved ? '20px 0px 0px 20px': '0'};

  border-top: ${({ approved }) => approved ? '2px solid #7800FF': ''};
  border-left: ${({ approved }) => approved ? '2px solid #7800FF': ''};
  border-bottom: ${({ approved }) => approved ? '2px solid #7800FF': ''};
  // border: 1px solid red;
  background: ${colors.white};


  @media (max-width: 714px) {
    max-width: none;
    border-radius: ${({ showDistribution }) =>
      showDistribution ? '5px' : '5px 5px 0px 0px'};
  }

  @media ${device.tabletMax} {
    // border:none;
  }

  @media (max-width: 714px) {
    border:none;
    margin-bottom: 2px;
  }

`;

const TopRow = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin: 0 auto 0 5px;
  }
`;

const InnerWrapper = styled.div<{ approved:boolean }>`
  background: ${({ approved }) => approved ? `url('/faded.png') lightgray 50% / cover no-repeat`: 'unset'};
  border-radius: ${({ approved }) => approved ? '20px 0px 0px 20px' : '0px'};
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 714px) {
    border-radius: 0;
  }
`;

const Row = styled.div<{ showDistribution: boolean; isApproved: boolean }>`
  min-height: 125px;
  background: transparent;

  display: flex;
  gap: 2px;

  overflow: hidden;

  @media (max-width: 714px) {
    flex-direction: column;
    gap: ${({ showDistribution }) => (showDistribution ? '5px' : '2px')};
    height: auto;
    border-radius: 20px !important;

  }
  @media (max-width: 714px) {
    border: ${({ isApproved }) => isApproved ? '2px solid #7800FF': ''};
  }

  @media ${device.tabletMax} {
    // border: ${({ isApproved }) => isApproved ? '2px solid #7800FF': ''};
    gap: 0;
  }
  @media ${device.mobile} {
    border-radius: 20px !important;
  }

  &:last-child  {
    border-radius: ${({ isApproved }) => !isApproved ? '0px 0px 0px 5px': 'auto'};
  }
`;

const containerHeight = 105;

export const AllCompetitorTitleContainer = styled.div`
  margin: 0 10px 0 12px;
  min-width: 125px;
  height: ${containerHeight}px;
  background: ${colors.white};
  padding: 5px 5px 7px;
  border-radius: 5px 5px 0 0;

  @media ${device.desktopMin} {
    max-width: 125px;
  }
  @media ${device.tabletMax} {
    width: 100%;
  }
  @media ${device.mobile} {
    // min-width: 130px;
  }
`;
export const AverageCompetitorTitleContainer = styled.div`
  min-width: 125px;
  height: ${containerHeight}px;
  background: ${colors.white};
  padding: 5px 5px 7px;
  border-radius: 5px 5px 0 0;

  @media ${device.desktopMin} {
    max-width: 125px;
  }
  @media ${device.tabletMax} {
    width: 100%;
  }
  &:first-child {
    @media ${device.tabletMax} {
      border-radius: 0px 0px 0px 5px;
    }
  }
  &:last-child {
    @media ${device.tabletMax} {
      border-radius: 0px 0px 5px 0px;
    }
  }
  @media ${device.mobile} {
    height: 110px;
    // min-width: 130px;
  }
  
`;
export const CompetitorTitleContainer = styled.div`
  min-width: 125px;
  height: ${containerHeight}px;
  background: ${colors.white};
  padding: 5px 5px 7px;

  // Apply border-radius for the first and last CompetitorTitleContainer
  &:first-of-type {
    border-radius: 10px 0 0 0;
  }
  
  &:last-of-type {
    border-radius: 0 10px 0 0;
  }

  @media ${device.desktopMin} {
    max-width: 125px;
  }

  @media ${device.tabletMax} {
    width: 100%;

    // Apply border-radius for the first and last CompetitorTitleContainer on tabletMax screen size
    &:first-of-type {
      border-radius: 0 0 0 5px;
    }
    
    &:last-of-type {
      border-radius: 0 0 5px 0;
    }
  }

  @media (max-width: 714px) {
    height: 140px;
  }

  @media ${device.mobile} {
    // Uncomment if you want to apply min-width on mobile
    // min-width: 130px;
  }
`;


const GraphsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;
  // flex: 1;
  height: 100%;


`;


const HeaderTitleContainer = styled.div`
  display: flex;
  gap: 2px;
  flex: none;

  ${CompetitorTitleContainer}:nth-child(2) {
    border-radius: 5px 0 0 0;
  }
  
  ${CompetitorTitleContainer}:nth-last-child(1) {
    border-radius: 0 5px 0 0;
  }

  @media ${device.tabletMax} {
    width: 100%;

    ${CompetitorTitleContainer}:nth-child(2) {
      border-radius: 0 0 0 5px;
    }
    
    ${CompetitorTitleContainer}:nth-last-child(1) {
      border-radius: 0 0 5px 0;
    }
  }
`;

export const CompetitorTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  svg {
    width: 40px;
    height: 40px;
  }
`;

const CompetitorRating = styled.div`
  margin-top: 5px;
  border-radius: 15px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
  font-size: 15px;

  span {
    color: ${colors.black70};
  }

  @media ${device.mobile} {
    font-size: 22px;
    height: 30px;

  }
`;

export const CompetitorImage = styled(Image)<{ imageURL: string }>`
  margin-right: 5px;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
`;

export const CompetitorTitle = styled(ButtonLabel)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 0px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  position: relative; /* Position relative for tooltip positioning */

  width: 90%;

`;

export const StarIcon = styled(Icon)`
  opacity: 0.5;
  margin-right: -5px;

  svg {
    margin-left: -10px;
  }
`;

function getCompetitorRatingColor(hasRating: boolean, score: number): string {
  if (!hasRating) return colors.white;
  if (score <= 1) return colors.purple20;
  if (score <= 2) return colors.purple15;
  if (score <= 3) return colors.purple10;
  if (score <= 4) return colors.purple05;
  if (score <= 5) return colors.purple05;
  return colors.purple05;
}

interface Props {
  groups: PostItGroupFragment[];
  groups2: PostItGroupFragment[];
  stakeholder?: Stakeholder;
  strategyId: number;
  drugId: number;
  stakeholderDefinitionId: number;
  competitorId?: number;
  competitors: CompetitorFragment[];
  showDistribution: boolean;
  selectedRegion: string;
  selectedRegions: string[];
  setShowDistribution: Dispatch<SetStateAction<boolean>>;
}

export const EvaluationStakeHolder = ({
  groups,
  groups2,
  strategyId,
  drugId,
  stakeholder,
  selectedRegion,
  selectedRegions,
  stakeholderDefinitionId,
  competitorId,
  competitors,
  showDistribution,
}: Props) => {
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const isMobile = useWidth() < 715;

  const graphsTitleContainerRef = useRef<HTMLDivElement>(null);

  const [
    desktopGraphsNode,
    setDesktopGraphsNode,
  ] = useState<HTMLDivElement | null>(null);

  const [rowRefs, setRowRefs] = useState<
    Record<number, RefObject<HTMLDivElement>>
  >({});

  const [
    distributionModalRatings,
    setDistributionModalRatings,
  ] = useState<DistributionModalValues | null>(null);

  const { data: strategy, loading } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  const currentStrategyUsers = strategy?.strategy?.users?.flat() || [];
  const currentDrugUsers = strategy?.strategy?.drug?.users?.flat() || [];

  const currentStrategyAndDrugUniqueUsers = lodash
  .uniqBy([...currentStrategyUsers, ...currentDrugUsers], 'id')
  .filter((user) =>
    selectedRegions.some((region) =>
      user.country?.toLowerCase().includes(region.toLowerCase())
    )
  );
  const [
    competitiveLandscapeOpportunityUpdate,
  ] = useCompetitiveLandscapeOpportunityUpdateMutation();

  const [scrollContainerMetrics, setScrollContainerMetrics] = useState({
    scrollWidth: desktopGraphsNode?.scrollWidth || 0,
    offsetWidth: desktopGraphsNode?.offsetWidth || 0,
    scrollLeft: desktopGraphsNode?.scrollLeft || 0,
  });

  const onRefChange = useCallback((node) => {
    setDesktopGraphsNode(node); // trigger re-render on changes
  }, []);

  const [whiteSpaceVisible, setWhiteSpaceVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState('Stakeholder');

  const handleOptionChange = (option: any) => {
    setSelectedOption(option.target.value);
  };
  const allRatings: CompetitiveLandscapeRatingFragment[] = groups2.reduce(
    (acc: CompetitiveLandscapeRatingFragment[], group) => {
      if (group?.competitiveLandscapeRating) {
        return [
          ...acc,
          ...(group.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[]),
        ];
      }
      return acc;
    },
    []
  );

  useLayoutEffect(() => {
    function onScrollHandler() {
      const whiteSpace = document.querySelector('.graph__white-space');

      const whiteSpaceVisible = whiteSpace?.getBoundingClientRect().width;

      setWhiteSpaceVisible(!!whiteSpaceVisible);
      if (desktopGraphsNode)
        setScrollContainerMetrics({
          scrollWidth: desktopGraphsNode.scrollWidth || 0,
          offsetWidth: desktopGraphsNode.offsetWidth || 0,
          scrollLeft: desktopGraphsNode.scrollLeft || 0,
        });
    }

    if (showDistribution && !isMobile) {
      onScrollHandler();

      const throttledScrollHandler = throttle(
        () => {
          onScrollHandler();
        },
        300,
        { trailing: true, leading: false }
      );

      if (desktopGraphsNode) {
        desktopGraphsNode.addEventListener('scroll', throttledScrollHandler);
        window.addEventListener('resize', throttledScrollHandler);
      }

      const element = desktopGraphsNode;

      return () => {
        element?.removeEventListener('scroll', throttledScrollHandler);
        window?.removeEventListener('resize', throttledScrollHandler);
      };
    }
  }, [showDistribution, isMobile, desktopGraphsNode]);

  useEffect(() => {
    // add or remove refs
    setRowRefs(() =>
      groups.reduce((acc, val) => {
        if (!!val) {
          acc[val.id as keyof typeof acc] = createRef();
          return acc;
        }
        return acc;
      }, {} as Record<number, RefObject<HTMLDivElement>>)
    );
  }, [groups]);

  const scrollerHeight =
    //Header
    containerHeight +
    //Row
    Number(graphsTitleContainerRef.current?.offsetHeight || 0) +
    //Gap
    2;

  const scrollAmount =
    //at start
    scrollContainerMetrics.scrollLeft === 0
      ? 261
      : //at end
      scrollContainerMetrics.scrollLeft + scrollContainerMetrics.offsetWidth ===
        scrollContainerMetrics.scrollWidth
      ? 229
      : //in-between
        242;

  function handleScrollerClick(direction: 'left' | 'right') {
    if (!desktopGraphsNode) return;

    desktopGraphsNode.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      top: 0,
      behavior: (window as any).safari ? 'auto' : 'smooth',
    });
  }

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loading style={{ width: 80, marginTop: 15 }} />
      </div>
    );
  }

  const stakeHolders = [
  {
    id: 1,
    title: 'Patients',
    color: colors.purple,
    type: Stakeholder.Patient,
    icon: 'Patient'
  },
  {
    id: 2,
    title: 'HCPs',
    color: colors.green,
    type: Stakeholder.Healthcare,
    icon: 'HCP'
  },
  {
    id: 3,
    title: 'Payors',
    color: colors.blue,
    type: Stakeholder.Payor,
    icon: 'Dollar'
  }
  ]

  function handleSetDistributionModalRatings(params: DistributionModalValues, type: string) {
    const notRatedTitle = `This outcome is not applicable for this ${type}`;
    const notRatedSubtitle = `This outcome was not included for this ${type} in the analysis.`;
    setDistributionModalRatings(params?.hideRatings === false ? params : {
      title: notRatedTitle,
      subtitle: notRatedSubtitle,
      ratings: [],
      hideRatings: true,
    });
  }

  const compAverageScores = stakeHolders.map((stakeholder) => {
    const compRatings = allRatings
      .filter(
        (rating) =>
          rating.stakeholder === stakeholder.type &&
          rating.stakeholderDefinitionId === stakeholderDefinitionId &&
          (selectedRegions.includes('global') || selectedRegions.includes(rating.region ?? ''))
      );
  
    const compAverageScore = compRatings.length
      ? average(compRatings.map((val) => val.score)).toFixed(1)
      : null; // Return null if there are no ratings
  
    return compAverageScore ? parseFloat(compAverageScore) : null; // Convert to number or return null
  });
  
  // Step 2: Calculate the global average of all `compAverageScore` values
  const validScores = compAverageScores.filter((score) => score !== null); // Remove nulls
  const globalAverageScore: any = validScores.length
    ? (validScores.reduce((acc: any, score: any) => acc + score, 0) / validScores.length).toFixed(1)
    : 0;


  return (
    <div style={{margin: '0 15px 50px'}}>
      <DistributionModal
        companyUsersData={currentStrategyAndDrugUniqueUsers}
        visible={!!distributionModalRatings}
        handleClose={() => {
          setDistributionModalRatings(null);
        }}
        title={distributionModalRatings?.title}
        subtitle={distributionModalRatings?.subtitle}
        ratings={distributionModalRatings?.ratings}
        hideRatings={distributionModalRatings?.hideRatings}
      />

      <Tooltip id={'Evaluation'} effect="float" place="bottom" />

      {/* <Toggle
        value={showDistribution}
        onChange={() => {
          setShowDistribution(!showDistribution);
        }}
        label="Show competitor distribution"
      /> */}

      
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          alignItems: 'flex-start',

        }}
      >
        <div
          ref={graphsTitleContainerRef}
          id="graphsTitleContainer"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 15 : 2,
            width: isMobile ? '100%' : 'auto',
          }}
        >
      <div
        style={{
          background: 'white',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          // display: 'flex',
          // alignItems: 'center',
          // width: '99%',
          padding: '10px 15px',
          height: containerHeight
          // marginTop: '40px'
        }}
      >
        <ViewBy>View by</ViewBy>
        
        <label
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '7px 3px',
            // border: `.5px solid ${selectedOption === 'Competitor' ? '#6200EE' : '#AAA'}`,
            // borderRadius: '0px 5px 5px 0px',
            // backgroundColor: selectedOption === 'Competitor' ? '#F3E5F5' : '#FFF',
          }}
        >
          <input
            type="radio"
            value="Competitor"
            checked={selectedOption === 'Competitor'}
            onChange={handleOptionChange}
            style={{ display: 'none' }}
          />
          <span
            style={{
              display: 'inline-block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              border: `2px solid ${selectedOption === 'Competitor' ? '#7800FF' : '#AAA'}`, // Outer border with purple when Competitor is selected
              marginRight: '8px',
              position: 'relative', // Relative positioning for inner circle
            }}
          >
            {selectedOption === 'Competitor' && (
              <span
                style={{
                  display: 'inline-block',
                  width: '8px', // Smaller inner circle
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: '#6200EE', // Purple fill
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Centering the inner circle
                }}
              ></span>
            )}
          </span>
          Competitor
        </label>
        <label 
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            // width: '100%',
            padding: '7px 3px',
            // border: `.5px solid ${selectedOption === 'Stakeholder' ? '#6200EE' : '#AAA'}`,
            // borderRadius: '5px 0px 0px 5px',
            // backgroundColor: selectedOption === 'Stakeholder' ? '#F3E5F5' : '#FFF',
          }}
        >
          <input
            type="radio"
            value="Stakeholder"
            checked={selectedOption === 'Stakeholder'}
            onChange={handleOptionChange}
            style={{ display: 'none' }}
          />
          <span
            style={{
              display: 'inline-block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              border: `2px solid ${selectedOption === 'Stakeholder' ? '#6200EE' : '#AAA'}`, // Outer border with purple when Stakeholder is selected
              marginRight: '8px',
              position: 'relative', // Relative positioning for inner circle
            }}
          >
            {selectedOption === 'Stakeholder' && (
              <span
                style={{
                  display: 'inline-block',
                  width: '8px', // Smaller inner circle
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: '#6200EE', // Purple fill
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Centering the inner circle
                }}
              ></span>
            )}
          </span>
          Stakeholder
        </label>

      </div>

      {isMobile && (
        <div style={{ marginTop: 20}}>
          <AverageAcross>Average across outcomes</AverageAcross>

            <TabGroup componentName="CompetitveLandscapeCompetitor">
        <HeaderTitleContainer>
          {selectedOption !== 'Stakeholder' ? 
            <>
            <AverageCompetitorTitleContainer>
                      <CompetitorTitleWrapper>
                        <ButtonRound
                          size={40}
                          iconName="Treatment"
                          level="primary"
                        />
                     
                        <CompetitorTitle data-tooltip="All">All</CompetitorTitle>
                      </CompetitorTitleWrapper>
                      <CompetitorRating
                        style={{
                          backgroundColor: getCompetitorRatingColor(
                            globalAverageScore > 0,
                            +globalAverageScore
                          ),
                        }}
                      >
                        {globalAverageScore ? (
                          <>
                          {globalAverageScore}
                          </>
                        ) : (
                          'No ratings'
                        )}
                      </CompetitorRating>
                    </AverageCompetitorTitleContainer>
            {competitors.map((competitor) => {
              const compRatings = allRatings
                .slice()
                .filter((rating) => rating.competitorId === competitor.id);

              const compAverageScore = average(
                compRatings.map((val) => val.score)
              ).toFixed(1);

              return (
                //Competitor Headers - Mobile
                <AverageCompetitorTitleContainer
                  key={competitor.id}
                  style={{ marginRight: 2 }}
                >
                  <CompetitorTitleWrapper>
                    {competitor.image ? (
                      <CompetitorImage imageURL={competitor.image} />
                    ) : (
                      <TreatmentIcon />
                    )}
                    <CompetitorTitle>{competitor.title}</CompetitorTitle>

                  </CompetitorTitleWrapper>
                  <CompetitorRating
                    style={{
                      backgroundColor: getCompetitorRatingColor(
                        compRatings.length > 0,
                        +compAverageScore
                      ),
                    }}
                  >
                    {compRatings.length > 0 ? (
                      <>
                        {compAverageScore}
                      </>
                    ) : (
                      'No ratings'
                    )}
                  </CompetitorRating>
                </AverageCompetitorTitleContainer>
              );
            })}
            </> : <>
                    <AverageCompetitorTitleContainer>
                      <CompetitorTitleWrapper>
                        <div style={{display: 'flex'}}>
                        {stakeHolders.map((stakeholder) => 
                              <Icon size={40} name={stakeholder.icon as IconName}  color={stakeholder.color}/>
                        )}
                        </div>                          
                        <CompetitorTitle>All</CompetitorTitle>
                      </CompetitorTitleWrapper>
                      <CompetitorRating
                        style={{
                          backgroundColor: getCompetitorRatingColor(
                            globalAverageScore > 0,
                            +globalAverageScore
                          ),
                        }}
                      >
                        {globalAverageScore ? (
                          <>
                          {globalAverageScore}
                          </>
                        ) : (
                          'No ratings'
                        )}
                      </CompetitorRating>
                    </AverageCompetitorTitleContainer>
                  {stakeHolders.map((stakeholder) => {
                    const compRatings = allRatings
                      .slice()
                      .filter(
                        (rating) => rating.stakeholder === stakeholder.type && rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegions.includes('global') || selectedRegions.includes(rating.region ?? ''))

                      );

                    const compAverageScore = average(
                      compRatings.map((val) => val.score)
                    ).toFixed(1);

                    return (
                      //Competitor Headers - Desktop
                      <AverageCompetitorTitleContainer key={stakeholder.id}>
                        <CompetitorTitleWrapper>
                          <Icon size={40} name={stakeholder.icon as IconName}  color={stakeholder.color}/>

                          {/* {stakeholder?.image ? (
                            <CompetitorImage imageURL={stakeholder?.image} />
                          ) : (
                            <TreatmentIcon />
                          )} */}
                          <CompetitorTitle>{stakeholder.title}</CompetitorTitle>

                          {/* {competitor?.CompetitorDetails?.some(
                            (s) =>
                              s?.stakeholder === stakeholder &&
                              s.stakeholderDefinitionId ===
                                stakeholderDefinitionId &&
                              !!s.isStarred
                          )} */}
                        </CompetitorTitleWrapper>
                        <CompetitorRating
                          style={{
                            backgroundColor: getCompetitorRatingColor(
                              compRatings.length > 0,
                              +compAverageScore
                            ),
                          }}
                        >
                          {compRatings.length > 0 ? (
                            <>
                              {compAverageScore}
                            </>
                          ) : (
                            'No ratings'
                          )}
                        </CompetitorRating>
                      </AverageCompetitorTitleContainer>
                    );
                  })}

                  </>
            }
        </HeaderTitleContainer>
        </TabGroup>
        </div>
      )} 

          {groups.map((group, i) => {
            const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
            const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

            const ratings = competitiveLandscapeRating
              .slice()
              .filter(
                (rating) =>
                  rating.stakeholderDefinitionId === stakeholderDefinitionId
              );

            const averageScore = average(
              ratings.map((val) => val.score)
            ).toFixed(1);

            const scoreDistribution = createScoreDistribution(ratings);

            const selectedOpportunity = competitiveLandscapeOpportunities.find(
              (opportunity) => {
                return (
                  opportunity.stakeholderDefinitionId ===
                    stakeholderDefinitionId
                );
              }
            );

            return (
              <Row
                key={group.id}
                ref={rowRefs[group.id]}
                showDistribution={showDistribution}
                isApproved={selectedOpportunity?.approved || false}
              >
                <TitleContainer
                  competitiveLandscapeOpportunityUpdate={
                    competitiveLandscapeOpportunityUpdate
                  }
                  group={group}
                  selectedOpportunity={selectedOpportunity}
                  isLead={isLead}
                  showDistribution={showDistribution}
                />
              {selectedOption === 'Stakeholder' ? 
                <>
                {/* Graphs for mobile view */}
                {isMobile && (
                  <EvaluationGraphsStakeholder
                  companyUsersData={currentStrategyAndDrugUniqueUsers}
                  isLead={isLead}
                  rowRefs={rowRefs}
                  key={i}
                  allRatings={allRatings}
                  isMobile={isMobile}
                  selectedRegion={selectedRegion}
                  selectedRegions={selectedRegions}

                  showDistribution={showDistribution}
                  competitors={competitors}
                  stakeHolders={stakeHolders}
                  stakeholderDefinitionId={stakeholderDefinitionId}
                  group={group}
                  groups2={groups2}
                  overallAverageScore={+averageScore}
                  scoreDistribution={scoreDistribution}
                  setDistributionModalRatings={({
                    subtitle,
                    ratings,
                    hideRatings,
                  }: Omit<DistributionModalProps, 'title'>) => {
                    handleSetDistributionModalRatings({
                      title: group.title,
                      subtitle,
                      ratings,
                      hideRatings
                    }, 'stakeholder');
                  }}
                />
                )}
                </>
                : 
                <>
                {isMobile && (
                    <EvaluationGraphsCompetitor
                      isLead={isLead}
                      rowRefs={rowRefs}
                      key={group.id}
                      companyUsersData={currentStrategyAndDrugUniqueUsers}
                      isMobile={isMobile}
                      showDistribution={showDistribution}
                      competitors={competitors}
                      group={group}
                      selectedRegion={selectedRegion}
                      selectedRegions={selectedRegions}
                      groups2={groups2}
                      stakeholderDefinitionId={stakeholderDefinitionId}
                      competitorId={competitorId}
                      overallAverageScore={+averageScore}
                      scoreDistribution={scoreDistribution}
                      setDistributionModalRatings={({
                        subtitle,
                        ratings,
                        hideRatings,
                      }: Omit<DistributionModalProps, 'title'>) => {
                        handleSetDistributionModalRatings({
                          title: group.title,
                          subtitle,
                          ratings,
                          hideRatings
                        }, 'competitor');
                      }}
                    />
                  )}
                  </>
                  }
              </Row>
            );
          })}
        </div>

        {/* Graphs for Desktop view */}
        {!isMobile && (
          <>
            {showDistribution && (
              // Left scroll
              <GraphScroller
                hide={() => {
                  const scrolledPastStart =
                    scrollContainerMetrics.scrollLeft === 0;

                  return scrolledPastStart;
                }}
                height={scrollerHeight}
                onClick={() => handleScrollerClick('left')}
              />
            )}

            {selectedOption === 'Stakeholder' ? 
            <GraphsWrapper className="GraphsWrapper" ref={onRefChange}>
              {showDistribution && (
                <HeaderTitleContainer
                >

                  <AllCompetitorTitleContainer>
                    <CompetitorTitleWrapper>
                      <div style={{display: 'flex'}}>
                      {stakeHolders.map((stakeholder) => 
                            <Icon size={40} name={stakeholder.icon as IconName}  color={stakeholder.color}/>
                      )}
                      </div>       
                      <CompetitorTitle 
                        >
                          All
                      </CompetitorTitle>
                    </CompetitorTitleWrapper>
                    <CompetitorRating
                      style={{
                        backgroundColor: getCompetitorRatingColor(
                          globalAverageScore > 0,
                          +globalAverageScore
                        ),
                      }}
                    >
                      {globalAverageScore ? (
                        <>
                          <span>Av. score</span> {globalAverageScore}
                        </>
                      ) : (
                        'No ratings'
                      )}
                    </CompetitorRating>
                  </AllCompetitorTitleContainer>
                  {stakeHolders.map((stakeholder) => {
                    const compRatings = allRatings
                      .slice()
                      .filter(
                        (rating) => rating.stakeholder === stakeholder.type && rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegions.includes('global') || selectedRegions.includes(rating.region ?? ''))

                      );

                    const compAverageScore = average(
                      compRatings.map((val) => val.score)
                    ).toFixed(1);

                    return (
                      //Competitor Headers - Desktop
                      <CompetitorTitleContainer key={stakeholder.id}>
                        <CompetitorTitleWrapper
                        >
                          <Icon size={40} name={stakeholder.icon as IconName}  color={stakeholder.color}/>
                          <CompetitorTitle>{stakeholder.title}</CompetitorTitle>
                        </CompetitorTitleWrapper>
                        <CompetitorRating
                          style={{
                            backgroundColor: getCompetitorRatingColor(
                              compRatings.length > 0,
                              +compAverageScore
                            ),
                          }}
                        >
                          {compRatings.length > 0 ? (
                            <>
                              <span>Av. score</span> {compAverageScore}
                            </>
                          ) : (
                            'No ratings'
                          )}
                        </CompetitorRating>
                      </CompetitorTitleContainer>
                    );
                  })}
                </HeaderTitleContainer>
              )}

              {groups.map((group, i) => {
                const filteredGroups = groups2.filter(g => g.title === group.title);
                let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

                // Iterate over each filtered group to collect ratings
                filteredGroups.forEach(filteredGroup => {
                  const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
                
                  // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
                  const ratings = competitiveLandscapeRating2.filter(
                    (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegions.includes('global') || selectedRegions.includes(rating.region ?? ''))
                  );
                
                  allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
                });
                

                const averageScore = average(
                  allRatings2.map((val) => val.score)
                ).toFixed(1);

                const scoreDistribution = createScoreDistribution(allRatings2);

                return (
                  !isMobile && (
                    <EvaluationGraphsStakeholder
                      companyUsersData={currentStrategyAndDrugUniqueUsers}
                      isLead={isLead}
                      rowRefs={rowRefs}
                      key={i}
                      isMobile={false}
                      selectedRegion={selectedRegion}
                      selectedRegions={selectedRegions}
                      showDistribution={showDistribution}
                      competitors={competitors}
                      stakeHolders={stakeHolders}
                      stakeholderDefinitionId={stakeholderDefinitionId}
                      group={group}
                      groups2={groups2}
                      overallAverageScore={+averageScore}
                      scoreDistribution={scoreDistribution}
                      setDistributionModalRatings={({
                        subtitle,
                        ratings,
                        hideRatings,
                      }: Omit<DistributionModalProps, 'title'>) => {
                        handleSetDistributionModalRatings({
                          title: group.title,
                          subtitle,
                          ratings,
                          hideRatings
                        }, 'stakeholder');
                      }}
                    />
                  )
                );
              })}
            </GraphsWrapper>
            :
             <GraphsWrapper className="GraphsWrapper" ref={onRefChange}>
              {showDistribution && (
                <HeaderTitleContainer
                
                >
                  <AllCompetitorTitleContainer>
                      <CompetitorTitleWrapper>
                        <ButtonRound
                          size={40}
                          iconName="Treatment"
                          level="primary"
                        />
                        <CompetitorTitle>All</CompetitorTitle>

                      </CompetitorTitleWrapper>
                      <CompetitorRating
                        style={{
                          backgroundColor: getCompetitorRatingColor(
                            globalAverageScore > 0,
                            +globalAverageScore
                          ),
                        }}
                      >
                        {globalAverageScore ? (
                          <>
                            <span>Av. score</span> {globalAverageScore}
                          </>
                        ) : (
                          'No ratings'
                        )}
                      </CompetitorRating>
                    </AllCompetitorTitleContainer>
                 
                  {competitors.map((competitor) => {
                    const compRatings = allRatings
                      .slice()
                      .filter(
                        (rating) => rating.competitorId === competitor.id && rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegions.includes('global') || selectedRegions.includes(rating.region ?? ''))

                      );

                    const compAverageScore = average(
                      compRatings.map((val) => val.score)
                    ).toFixed(1);

                    const showTooltip = competitor.title.length > 10;

                    return (
                      //Competitor Headers - Desktop
                      <CompetitorTitleContainer key={competitor.id}>
                        {showTooltip && 
                          <Tooltip id={competitor.id.toString()} effect="float" place="bottom"   />
                        }

                        <CompetitorTitleWrapper
                         data-for={competitor.id.toString()}
                         data-tip={competitor.title}
                         data-offset="{ 'top': 0 }"
                         >
                          {competitor.image ? (
                            <CompetitorImage imageURL={competitor.image} />
                          ) : (
                            <TreatmentIcon />
                          )}
                          <CompetitorTitle>{competitor.title}</CompetitorTitle>

                        </CompetitorTitleWrapper>
                        <CompetitorRating
                          style={{
                            backgroundColor: getCompetitorRatingColor(
                              compRatings.length > 0,
                              +compAverageScore
                            ),
                          }}
                        >
                          {compRatings.length > 0 ? (
                            <>
                              <span>Av. score</span> {compAverageScore}
                            </>
                          ) : (
                            'No ratings'
                          )}
                        </CompetitorRating>
                      </CompetitorTitleContainer>
                    );
                  })}
                </HeaderTitleContainer>
              )}

              {groups.map((group) => {
                const filteredGroups = groups2.filter(g => g.title === group.title);
                let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

                // Iterate over each filtered group to collect ratings
                filteredGroups.forEach(filteredGroup => {
                  const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
                
                  // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
                  const ratings = competitiveLandscapeRating2.filter(
                    (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
                    && (selectedRegions.includes('global') || selectedRegions.includes(rating.region ?? ''))

                  );
                
                  allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
                });
                

                const averageScore = average(
                  allRatings2.map((val) => val.score)
                ).toFixed(1);

                const scoreDistribution = createScoreDistribution(allRatings2);

                return (
                  !isMobile && (
                    <EvaluationGraphsCompetitor
                      isLead={isLead}
                      rowRefs={rowRefs}
                      key={group.id}
                      isMobile={false}
                      companyUsersData={currentStrategyAndDrugUniqueUsers}
                      showDistribution={showDistribution}
                      competitors={competitors}
                      group={group}
                      selectedRegion={selectedRegion}
                      selectedRegions={selectedRegions}
                      groups2={groups2}
                      stakeholderDefinitionId={stakeholderDefinitionId}
                      competitorId={competitorId}
                      overallAverageScore={+averageScore}
                      scoreDistribution={scoreDistribution}
                      setDistributionModalRatings={({
                        subtitle,
                        ratings,
                      }: Omit<DistributionModalProps, 'title'>) => {
                        setDistributionModalRatings({
                          title: group.title,
                          subtitle,
                          ratings,
                        });
                      }}
                    />
                  )
                );
              })}
            </GraphsWrapper>
          }

            {showDistribution && (
              <GraphScroller
                height={scrollerHeight}
                right
                hide={() => {
                  const roomToScroll =
                    scrollContainerMetrics.scrollWidth >=
                    scrollContainerMetrics.offsetWidth;

                  const scrolledToEnd =
                    scrollContainerMetrics.scrollLeft ===
                    scrollContainerMetrics.scrollWidth -
                      scrollContainerMetrics.offsetWidth;

                  return whiteSpaceVisible || (roomToScroll && scrolledToEnd);
                }}
                onClick={() => handleScrollerClick('right')}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const TitleContainer = ({
  isLead,
  competitiveLandscapeOpportunityUpdate,
  group,
  selectedOpportunity,
  showDistribution,
}: {
  isLead: boolean;
  group: PostItGroupFragment;
  competitiveLandscapeOpportunityUpdate: CompetitiveLandscapeOpportunityUpdateMutationFn;
  selectedOpportunity?: CompetitiveLandscapeOpportunityFragment;
  showDistribution: boolean;
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [isApproved, setIsApproved] = useState(selectedOpportunity?.approved);
  const [errorModal, setErrorModal] = useState(false);
  const closeErrorModal = () => setErrorModal(false);

  useEffect(() => {
    setIsApproved(selectedOpportunity?.approved);
  }, [selectedOpportunity?.approved]);

  async function toggleOpportunityApproved() {
    if (!selectedOpportunity) return alert('No opportunity id provided');

    setIsApproved(!isApproved);

    try {
      await competitiveLandscapeOpportunityUpdate({
        variables: {
          id: selectedOpportunity.id,
          data: {
            strategyId: group.strategyId,
            approved: !isApproved,
          },
        },
      });
    } catch (err) {
      setIsApproved(isApproved);

      if (
        err instanceof Error &&
        err.message.includes('Cannot remove this opportunity')
      ) {
        setErrorModal(true);
      } else {
        alert(`Error: Couldn't update this Opportunity`);
      }
    }
  }

  return (
    <TitleWrapper showDistribution={showDistribution} approved={isApproved || false}>
      <ErrorModal
        title="Cannot remove this opportunity"
        text="Content in later steps depends on this outcome as an opportunity. Remove content and try again."
        visible={errorModal}
        handleClose={closeErrorModal}
      />

      <InnerWrapper approved={isApproved || false}>
        <TopRow>
          <div
            data-for={'Evaluation'}
            data-tip={isLead ? '' : 'Only Leads can edit'}
            data-offset="{'top': 0}"
          >
            <CheckCircle
              onClick={toggleOpportunityApproved}
              className={`cypress-competitive-landscape-opportunity_${!!isApproved}`}
              isDisabled={!isLead}
              complete={!!isApproved}
            />
          </div>
          <BodySmall color={isApproved ? colors.black : colors.greyDark}>
            Opportunity
          </BodySmall>
          <Collaboration collaboration={selectedOpportunity?.collaboration} />
        </TopRow>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Subtitle2>{group.title}</Subtitle2>
        </div>
      </InnerWrapper>
    </TitleWrapper>
  );
};

export function createScoreDistribution(ratings: { score: number }[]) {
  return ratings.reduce((acc, val) => {
    if (acc[val.score]) {
      acc[val.score] = acc[val.score] + 1;
    } else {
      acc[val.score] = 1;
    }

    return acc;
  }, {} as Record<number, number>);
}