import { BodySmall, LineGraph, Icon } from 'components/shared';
import { colors} from 'constants/index';
import {
  CompetitiveLandscapeRatingFragment,
  CompetitorFragment,
  PostItGroupFragment,
  CompetitiveLandscapeOpportunity,
  User,
} from 'data/graphql/generated';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  CompetitorTitle,
  CompetitorTitleContainer,
  CompetitorTitleWrapper,
} from './EvaluationStakeHolder';
import { device } from 'utils/breakpoints';

import { average } from 'utils/average';
import { GraphScroller } from './GraphScroller';
import { throttle } from 'lodash';
import { ScorePill } from 'components/shared/ScorePill';
import { DistributionModalValues } from './DistributionModal';
import { isSafari } from 'utils/isSafari';
import { IconName } from 'types';
import { Admin } from './NAModal'
import getUniqueCombinedUsers from 'utils/getUniqueCombinedUsers';

const MOBILE_GRAPHS_CLASSNAME = 'Mobile-graphs-container';

const AllBoxNew = styled.div<{ isApproved: boolean; isLast: boolean }>`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 125px;           /* Set a height for vertical centering */
  width: 125px;            /* Optional: set width if needed */
  flex-direction: column;

  @media ${device.tabletMax} {
    border:none;
  }
`;
const BoxNew = styled.div<{ isApproved: boolean; isLast: boolean }>`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 125px;           /* Set a height for vertical centering */
  width: 125px;            /* Optional: set width if needed */
  flex-direction: column;

  @media ${device.tabletMax} {
    border:none;
  }
   
  @media (max-width: 714px) {
    height: auto;
  }
`;
const AverageScore = styled.div`
  color: var(--black, #141427);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
`;

const VoteCell = styled.div<{type: string}>`
  color: ${({ type }) => {
    if (type === 'success') return '#078766';
    if (type === 'warning') return '#FF1E61';
    return '#696975'; // default color if no match
  }};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;



const GraphAreaWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '125px')};

  background: ${colors.white};
  padding: 10px;
  border-radius: 0px 5px 5px 0px;

  @media (max-width: 714px) {
    border-radius: 0px 0px 5px 5px;
    height: auto;
  }
  
`;

const CompetitorsGraphAreaWrapper = styled.div<{
  isMobile: boolean;
  height?: string;
  average: number;
  isApproved: boolean;
  isLast: boolean;
  isNa: boolean;
}>`
  min-width: 125px;
  width: 125px;
  height: 100%;
  flex: none;
  background: ${({ average, isNa }) => {
    if (isNa) return 'white';
    if (average >= 1 && average < 2) return '#E4CCFF';
    if (average >= 2 && average < 3) return '#EBD9FF';
    if (average >= 3 && average < 4) return '#F2E6FF';
    if (average >= 4 && average <= 5) return '#F8F2FF';
    return '#FFF4F7'; // default color if no match
  }};

  border: 2px solid transparent; /* Initial transparent border */
  box-sizing: border-box; 

  border-radius: ${({ isLast, isApproved }) => (isLast && isApproved ? '0 20px 20px 0' : '0')} !important;
  // border-top-color: ${({ isApproved }) => isApproved ? '#7800FF': ''} !important;
  // border-bottom-color: ${({ isApproved }) => isApproved ? '#7800FF': ''} !important;
  // border-right-color: ${({ isLast, isApproved }) => isApproved && isLast ? '#7800FF': ''} !important;
  
  

  @media ${device.tabletMax} {
    flex: 1;

    justify-content: center;
    display: flex;
    border: none;
    width: 100%;
  }

  @media (max-width: 714px) {
    flex: none;
    min-width: auto;
    border-radius: 15px !important;

    height: 65px;
  }
  @media ${device.mobile} {
    min-width: auto;
    height: 65px;
  }

  &:hover {
    // border-color: var(--purple, #7800FF); /* Change border color on hover */
    cursor: pointer;
  }

  ${({ isApproved, isLast }) =>
    isApproved &&
    `
    &:hover {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 2px solid #7800FF;
      cursor: pointer;
      border-right: ${!isLast ? '2px solid #7800FF' : '2px solid transparent'};
    }
   
  `}
  

 
`;

const CompetitorGraphsScrollWrapper = styled.div<{ height?: string; isApproved: boolean;}>`
  display: flex;
  gap: 2px;
  height: ${({ height }) => (height ? height : '125px')};
  border: 2px solid transparent;
  box-sizing: border-box;
  @media ${device.desktopMin} {
    &:last-child ${CompetitorsGraphAreaWrapper}:last-child {

      ${({ isApproved }) =>
        !isApproved &&
          `
        border-radius: 0 0px 05px 0 !important;
        `};
    }
  }

  ${({ isApproved }) =>
    isApproved &&
  `
  @media (min-width: 714px) {


  border-color: #7800FF;
  border-radius: 0px 20px 20px 0px;
  border-left: 2px solid transparent;
  }

  `}
`;

const AllCompetitorsGraphAreaWrapper = styled.div<{
  isMobile: boolean;
  height?: string;
  average: number;
  isApproved: boolean;
  isLast: boolean;
  isNa: boolean;
}>`
  margin: 0 10px;
  min-width: 125px;
  width: 125px;
  height: 100%;
  flex: ${({ isMobile }) => (isMobile ? 1 : 'none')};
  background: ${({ average, isNa }) => {
    if (isNa) return 'white';
    if (average >= 1 && average < 2) return '#E4CCFF';
    if (average >= 2 && average < 3) return '#EBD9FF';
    if (average >= 3 && average < 4) return '#F2E6FF';
    if (average >= 4 && average <= 5) return '#F8F2FF';
    return 'white'; // default color if no match
  }};

  border: 2px solid transparent; /* Initial transparent border */
  box-sizing: border-box; 

  // border-radius: ${({ isLast }) => (isLast ? '0 10px 10px 0' : '0')};
  // border-top-color: ${({ isApproved }) => isApproved ? '#7800FF': ''};
  // border-bottom-color: ${({ isApproved }) => isApproved ? '#7800FF': ''};
  // border-right-color: ${({ isLast, isApproved }) => isApproved && isLast ? '#7800FF': ''};
  

  @media ${device.tabletMax} {
    flex: 1;

    justify-content: center;
    display: flex;
    border: none;
    width: 100%;
  }

  @media (max-width: 714px) {
    flex: none;
    min-width: auto;
    border-radius: 15px;

    height: 65px;
  }
  @media ${device.mobile} {
    min-width: auto;


    height: 65px;
  }

 
`;

const StyledLineGraph = styled(LineGraph)`
  height: 100%;
  max-height: 105px;

  .lineGraph__graphWrapper {
    height: 42px;
    margin-bottom: 20px;
  }

  @media (max-width: 714px) {
    .lineGraph__graphWrapper {
      height: 55px;
    }
  }
`;


const MobileGraphs = styled.div`
  width: 125px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
`;


export const EvaluationGraphsStakeholder = ({
  isMobile,
  showDistribution,
  group,
  groups2,
  allRatings,
  selectedRegion,
  selectedRegions,
  stakeHolders,
  companyUsersData,
  stakeholderDefinitionId,
  overallAverageScore,
  scoreDistribution,
  setDistributionModalRatings,
  rowRefs,
  key,
}: {
  key: number;
  isMobile: boolean;
  isLead: boolean;
  showDistribution: boolean;
  competitors: CompetitorFragment[];
  group: PostItGroupFragment;
  groups2: PostItGroupFragment[];
  stakeHolders: any;
  allRatings?: any;
  companyUsersData?: User[] | null;
  selectedRegion: string;
  selectedRegions: string[];
  stakeholderDefinitionId: any;
  overallAverageScore: number;
  scoreDistribution: Record<number, number>;
  setDistributionModalRatings({
    title,
    ratings,
    hideRatings,
  }: DistributionModalValues): void;
  rowRefs?: Record<number, React.RefObject<HTMLDivElement>>;
}) => {
  const mobileGraphsRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState('0px');

  const [scrollContainerMetrics, setScrollContainerMetrics] = useState({
    scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
    offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
    scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
  });

  useLayoutEffect(() => {
    if (showDistribution && isMobile) {
      setScrollContainerMetrics({
        scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
        offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
        scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
      });

      const throttledScrollHandler = throttle(
        () => {
          setScrollContainerMetrics({
            scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
            offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
            scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
          });
        },
        300,
        { trailing: true, leading: false }
      );

      if (mobileGraphsRef.current)
        mobileGraphsRef.current.addEventListener(
          'scroll',
          throttledScrollHandler
        );
      window.addEventListener('resize', throttledScrollHandler);
      const element = mobileGraphsRef.current;

      return () => {
        element?.removeEventListener('scroll', throttledScrollHandler);
        window?.removeEventListener('resize', throttledScrollHandler);
      };
    }
  }, [showDistribution, isMobile]);

  useLayoutEffect(() => {
    //Update row heights to match title rows
    if (rowRefs) {
      setHeight(Number(rowRefs[group.id]?.current?.offsetHeight) + 'px');
    }
  }, [rowRefs, group]);

  const handleScrollerClick = useCallback(
    (scrollDirection: 'left' | 'right') => {
      const mobileGraphs = document.getElementsByClassName(
        MOBILE_GRAPHS_CLASSNAME
      )?.[0];

      mobileGraphs?.scrollBy({
        left: scrollDirection === 'left' ? -240 : 240,
        top: 0,
        behavior: isSafari() ? 'auto' : 'smooth',
      });
    },
    []
  );

  const normalizeString = (str: string | null | undefined) => {
    return str
      ? str
          .toLowerCase()               // Convert to lowercase
          .replace(/-/g, ' ')          // Replace hyphens with spaces
          .trim()                      // Remove leading/trailing spaces
      : '';                            // Return an empty string if null or undefined
  };
  const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

  const selectedOpportunity =competitiveLandscapeOpportunities.find(
    (opportunity) => {
      return (
        opportunity.stakeholderDefinitionId ===
          stakeholderDefinitionId
      );
    }
  );

  const isApproved = selectedOpportunity?.approved;
  const averageScores = stakeHolders.map((competitor: any) => {
    const filteredGroups = groups2.filter(g => g.title === group.title && g.stakeholder === competitor.type);
    let allRatings2: any = [];
    
    filteredGroups.forEach(filteredGroup => {
      const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating || [];
      const ratings = competitiveLandscapeRating2.filter(
        (rating) =>
          rating.stakeholderDefinitionId === stakeholderDefinitionId &&
          (selectedRegions.map(normalizeString).includes('global') || selectedRegions.map(normalizeString).includes(normalizeString(rating.region)))
      );
      allRatings2 = [...allRatings2, ...ratings];
    });
  
    const scores = allRatings2.map((val: { score: any; }) => val.score);
    const hasValidScore = scores.some((score: number) => !isNaN(score));
    const isNa = !hasValidScore && filteredGroups.length > 0;
    
    // Treat "N/A" as 0 for global average calculation if it has some valid scores
    const adjustedScores = scores.map((score: number) => (isNaN(score) ? 0 : score));
    const averageScore = hasValidScore
      ? average(adjustedScores).toFixed(1)
      : 'N/A';
    
    return isNa ? 'N/A' : parseFloat(averageScore);
  });
  
  // Step 2: Calculate the global average based on conditions
  const validScores = averageScores.filter((score: any) => score !== 'N/A' && !isNaN(score)); // Filter out NaN and 'N/A'
  const allHaveNa = averageScores.every((score: any) => score === 'N/A' || isNaN(score));
  const noScores = averageScores.every((score: string) => score === 'N/A' || score === '-');
  
  const globalAverageScore = allHaveNa
    ? 'N/A'
    : noScores
    ? '-'
    : validScores.length
    ? (validScores.reduce((acc: any, score: any) => acc + score, 0) / validScores.length).toFixed(1)
    : 'N/A'; // Set to 'N/A' if no valid scores are present
  
  
  const lastStakeholderId = stakeHolders[stakeHolders.length - 1]?.id;

  if (isMobile) {
    return (
      <>
        {showDistribution ? (
          <div style={{ display: 'flex', gap: 2 }}>
            <GraphScroller
              // Left scroll
              hide={() => {
                const scrolledPastStart =
                  scrollContainerMetrics.scrollLeft === 0;

                return scrolledPastStart;
              }}
              onClick={() => handleScrollerClick('left')}
            />

            <MobileGraphs
              className={MOBILE_GRAPHS_CLASSNAME}
              ref={mobileGraphsRef}
            >
            <div
                style={{
                  display: 'flex',
                  gap: 2,
                }}
              >
                <CompetitorTitleContainer>
                    <CompetitorTitleWrapper>
                      <div style={{display: 'flex'}}>
                      {stakeHolders.map((stakeholder: { icon: string; color: string | undefined; }) => 
                            <Icon size={40} name={stakeholder.icon as IconName}  color={stakeholder.color}/>
                      )}
                      </div>                          
                      <CompetitorTitle>All</CompetitorTitle>
                    </CompetitorTitleWrapper>
                    
                    <CompetitorGraphsScrollWrapper 
                      isApproved={isApproved || false}
                    
                    className="competitorGraphsScrollWrapper">
              
                    <CompetitorsGraphAreaWrapper
                      isMobile={isMobile}
                      average={globalAverageScore === 'N/A' ? 0 : +globalAverageScore}
                      isApproved={isApproved || false}
                      isLast={false}
                      isNa={globalAverageScore === 'N/A' ? true : false}
                    >
                    
                      <BoxNew
                        isApproved={isApproved || false}
                        isLast={false}
                      >    
                        <AverageScore>{globalAverageScore}</AverageScore>
                      </BoxNew>
                    </CompetitorsGraphAreaWrapper>
                 
                  </CompetitorGraphsScrollWrapper>
                </CompetitorTitleContainer>
            {stakeHolders.map((stakeholder: any) => {
              const isLast = stakeholder.id === lastStakeholderId;

              const filteredGroups = groups2.filter(g => g.title === group.title && g.stakeholder === stakeholder.type);
              let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

              
              // Iterate over each filtered group to collect ratings
              filteredGroups.forEach(filteredGroup => {
                const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];

                // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
                const ratings = competitiveLandscapeRating2.filter(
                (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
                && (selectedRegions.map(normalizeString).includes('global') || selectedRegions.map(normalizeString).includes(normalizeString(rating.region)))
              );

                allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
              });

              const averageScore = average(
                allRatings2.map((val) => val.score)
              ).toFixed(1);


              const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

              const selectedOpportunity =competitiveLandscapeOpportunities.find(
              (opportunity) => {
                return (
                  opportunity.stakeholderDefinitionId ===
                    stakeholderDefinitionId
                );
              }
              );

              const isApproved = selectedOpportunity?.approved;
              const sortedRatings = allRatings2 || [];

              const { uniqueCombinedUsers, uniqueRatingUsers } = getUniqueCombinedUsers(
                sortedRatings,
                companyUsersData || undefined,
              );

              const isNa = !isNaN(Number(averageScore)) ?
                false
                : isNaN(Number(averageScore)) && filteredGroups.length > 0 ?
                false
                :
              true;
                    return (
                      //Competitor Headers - Desktop
                      <CompetitorTitleContainer key={stakeholder.id}>
                        <CompetitorTitleWrapper>
                          <Icon size={40} name={stakeholder.icon as IconName}  color={stakeholder.color}/>
                          <CompetitorTitle>{stakeholder.title}</CompetitorTitle>
                        </CompetitorTitleWrapper>
                      <CompetitorGraphsScrollWrapper className="competitorGraphsScrollWrapper"
                      isApproved={isApproved || false}
                      >
              
                          <CompetitorsGraphAreaWrapper
                            key={stakeholder.id}
                            isMobile={isMobile}
                            average={parseFloat(averageScore)}
                            isApproved={isApproved || false}
                            isLast={isLast}
                            isNa={isNa}
                          >
                          
                        <BoxNew onClick={() => {
                            if (isNa) return;
                            setDistributionModalRatings({
                              title: stakeholder.title,
                              ratings: allRatings2,
                              hideRatings: isNa
                            });
                          }}
                          isApproved={isApproved || false}
                          isLast={isLast}>
                        <AverageScore>
                        {!isNaN(Number(averageScore)) ? 
                          averageScore
                        : isNaN(Number(averageScore)) && filteredGroups.length > 0 ? '-' : ''}

                        </AverageScore>
                        {!isNaN(Number(averageScore)) ?
                        <VoteCell type={uniqueRatingUsers.length === uniqueCombinedUsers.length ? 'success' : 'default'}>
                            {uniqueRatingUsers.length} / {uniqueCombinedUsers.length}
                        </VoteCell>
                        : isNaN(Number(averageScore)) && filteredGroups.length > 0 ?
                        <VoteCell type={'warning'}>
                            (0/{uniqueCombinedUsers.length})
                        </VoteCell>
                        :
                        <VoteCell type={'default'}>
                          <Admin />
                        </VoteCell>
                        }
                      </BoxNew>
                        {/* <ScorePill value={+averageScore}>
                          {averageScore ? (
                            <>
                              <BodySmall
                                style={{ marginRight: 3 }}
                                color={colors.greyDark}
                              >
                                Av.
                              </BodySmall>
                              <BodySmall color={colors.black}>
                                {'' + averageScore}
                              </BodySmall>
                            </>
                          ) : (
                            <BodySmall color={colors.greyDark}>
                              No ratings
                            </BodySmall>
                          )}
                        </ScorePill>
                        {!!isLead && (
                          <ChartButton
                            onClick={() => {
                              setDistributionModalRatings({
                                title: competitor.title,
                                // ratings: competitorRatings,
                              });
                            }}
                          />
                        )} */}

                      {/* <StyledCompetitorsLineGraph
                        showWeakStrongLabel={false}
                        values={scoreDistribution}
                        maxRating={5}
                      /> */}
                    </CompetitorsGraphAreaWrapper>
                 
              </CompetitorGraphsScrollWrapper>
                      </CompetitorTitleContainer>
                    );
                  })}
              </div>
           
            </MobileGraphs>
            <GraphScroller
              hide={() => {
                const roomToScroll =
                  scrollContainerMetrics.scrollWidth >=
                  scrollContainerMetrics.offsetWidth;

                const scrolledToEnd =
                  scrollContainerMetrics.scrollLeft ===
                  scrollContainerMetrics.scrollWidth -
                    scrollContainerMetrics.offsetWidth;

                return roomToScroll && scrolledToEnd;
              }}
              right
              onClick={() => handleScrollerClick('right')}
            />
          </div>
        ) : (
          <GraphAreaWrapper>
            <ScorePill value={overallAverageScore} />

            <StyledLineGraph
              showWeakStrongLabel={!isMobile}
              values={scoreDistribution}
              maxRating={5}
            />
          </GraphAreaWrapper>
        )}
      </>
    );
  }


  return showDistribution ? (
    <CompetitorGraphsScrollWrapper height={height}
    isApproved={isApproved || false}
    >
        <AllCompetitorsGraphAreaWrapper 
          isMobile={isMobile} 
          average={Number(globalAverageScore)}
          isApproved={isApproved || false}
          isLast={false}
          isNa={false}
        >
        <AllBoxNew
          isApproved={isApproved || false}
          isLast={false}
        >    
        <AverageScore>{globalAverageScore}</AverageScore>
        </AllBoxNew>
      </AllCompetitorsGraphAreaWrapper>
      {stakeHolders.map((competitor: any) => {
        const isLast = competitor.id === lastStakeholderId;
       const filteredGroups = groups2.filter(g => g.title === group.title && g.stakeholder === competitor.type);
       let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

      
       // Iterate over each filtered group to collect ratings
       filteredGroups.forEach(filteredGroup => {
         const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
       
         // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
         const ratings = competitiveLandscapeRating2.filter(
          (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
          && (selectedRegions.map(normalizeString).includes('global') || selectedRegions.map(normalizeString).includes(normalizeString(rating.region)))
        );

         allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
       });

       const averageScore = average(
         allRatings2.map((val) => val.score)
       ).toFixed(1);

        const sortedRatings = allRatings2 || [];

        const { uniqueCombinedUsers, uniqueRatingUsers } = getUniqueCombinedUsers(
          sortedRatings,
          companyUsersData || undefined,
        );


        const isNa = !isNaN(Number(averageScore)) ?
          false
          : isNaN(Number(averageScore)) && filteredGroups.length > 0 ?
          false
          :
        true;
        return (
            <CompetitorsGraphAreaWrapper 
              key={competitor.id} 
              isMobile={isMobile} 
              average={Number(averageScore)}
              isApproved={isApproved || false}
              isLast={isLast}
              isNa={isNa}
            >
              <BoxNew onClick={() => {
                    if (isNa) return;
                    setDistributionModalRatings({
                      title: competitor.title,
                      ratings: allRatings2,
                      hideRatings: isNa
                    });
                  }}
                  
                  isApproved={isApproved || false}
                  isLast={isLast}>
                <AverageScore>
                  {!isNaN(Number(averageScore)) ? 
                    averageScore
                  : isNaN(Number(averageScore)) && filteredGroups.length > 0 ? '-' : ''}

                  </AverageScore>
                  {!isNaN(Number(averageScore)) ?
                  <VoteCell type={uniqueRatingUsers.length === uniqueCombinedUsers.length ? 'success' : 'default'}>
                      {uniqueRatingUsers.length} / {uniqueCombinedUsers.length}
                  </VoteCell>
                  : isNaN(Number(averageScore)) && filteredGroups.length > 0 ?
                  <VoteCell type={'warning'}>
                      (0/{uniqueCombinedUsers.length})
                  </VoteCell>
                  :
                  <VoteCell type={'default'}>
                    <Admin />
                  </VoteCell>
                  }
              </BoxNew>
              {/* <ScorePill value={+averageScore}>
                {averageScore ? (
                  <>
                    <BodySmall
                      style={{ marginRight: 3 }}
                      color={colors.greyDark}
                    >
                      Av.
                    </BodySmall>
                    <BodySmall color={colors.black}>
                      {'' + averageScore}
                    </BodySmall>
                  </>
                ) : (
                  <BodySmall color={colors.greyDark}>No ratings</BodySmall>
                )}
              </ScorePill>
              {!!isLead && (
                <ChartButton
                  onClick={() => {
                    setDistributionModalRatings({
                      title: competitor.title,
                      ratings: allRatings2,
                    });
                  }}
                />
              )} */}

            {/* <StyledCompetitorsLineGraph
              showWeakStrongLabel={false}
              values={scoreDistribution}
              maxRating={5}
            /> */}
          </CompetitorsGraphAreaWrapper>
        );
      })}
      {/* Remaining White space */}
      {/* <div
        className="graph__white-space"
        style={{
          width: '100%',
          height: '100%',
          flex: 1,
          borderRadius: '0 5px 5px 0',
          background: colors.white,
        }}
      /> */}
    </CompetitorGraphsScrollWrapper>
  ) : (
    <GraphAreaWrapper height={height}>
      {isMobile && (
        <ScorePill value={overallAverageScore}>
          {overallAverageScore ? (
            <>
              <BodySmall style={{ marginRight: 3 }} color={colors.greyDark}>
                Av.
              </BodySmall>
              <BodySmall color={colors.black}>
                {'' + overallAverageScore}
              </BodySmall>
            </>
          ) : (
            <BodySmall color={colors.greyDark}>No ratings</BodySmall>
          )}
        </ScorePill>
      )}
      <StyledLineGraph
        showWeakStrongLabel={!isMobile}
        values={scoreDistribution}
        maxRating={5}
      />
    </GraphAreaWrapper>
  );
};
